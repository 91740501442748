"use strict";

var _interopRequireDefault = require("C:/Users/Administrator/Desktop/hyzk/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
_vue.default.use(_vueRouter.default);
var routes = [{
  path: '/',
  name: 'IndexPage',
  component: function component(resolve) {
    return require(['@/views/indexPage.vue'], resolve);
  },
  meta: {
    title: '首  页',
    icon: 'index',
    affix: true,
    noCache: true
  }
}, {
  path: '/privacy',
  name: 'privacy',
  component: function component(resolve) {
    return require(['@/views/agreement/PrivacyPolicy.vue'], resolve);
  },
  meta: {
    title: '隐私政策',
    icon: 'index',
    affix: true,
    noCache: true
  }
}, {
  path: '/useragr',
  name: 'useragr',
  component: function component(resolve) {
    return require(['@/views/agreement/UserAgreement.vue'], resolve);
  },
  meta: {
    title: '用户协议',
    icon: 'index',
    affix: true,
    noCache: true
  }
}];
var router = new _vueRouter.default({
  mode: 'history',
  // base: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/',
  routes: routes
});
var _default = router;
exports.default = _default;