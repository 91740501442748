"use strict";

var _interopRequireDefault = require("C:/Users/Administrator/Desktop/hyzk/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("C:\\Users\\Administrator\\Desktop\\hyzk\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("C:\\Users\\Administrator\\Desktop\\hyzk\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es6.promise.js");
require("C:\\Users\\Administrator\\Desktop\\hyzk\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("C:\\Users\\Administrator\\Desktop\\hyzk\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _App = _interopRequireDefault(require("./App.vue"));
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
require("./assets/style/index.scss");
require("./assets/style/element-variables.scss");
require("normalize.css/normalize.css");
var _animate = _interopRequireDefault(require("animate.css"));
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
var _vueVideoPlayer = _interopRequireDefault(require("vue-video-player"));
require('video.js/dist/video-js.css');
require('vue-video-player/src/custom-theme.css');
_vue.default.use(_vueVideoPlayer.default);
_vue.default.use(_animate.default);
_vue.default.use(_elementUi.default);
_vue.default.config.productionTip = false;
new _vue.default({
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount('#app');